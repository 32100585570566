import baseMoment from 'moment'
import { isWeekDay, isWeekendDay } from 'moment-business'
import { extendMoment } from 'moment-range'

// @ts-ignore
const moment = extendMoment(baseMoment)

// 今年假日
const holidayList = [
  '2024-01-01',
  '2024-02-08',
  '2024-02-09',
  '2024-02-10',
  '2024-02-11',
  '2024-02-12',
  '2024-02-13',
  '2024-02-14',
  '2024-02-28',
  '2024-04-04',
  '2024-04-05',
  '2024-05-01',
  '2024-06-10',
  '2024-09-17',
  '2024-10-10',
]

// 今年補班日
const forceBusinessDayList = ['2024-02-17']

// 確認是否為國定假日
export function isHoliday(date: moment.Moment) {
  return holidayList.reduce(
    (pre, cur) => pre || date.isSame(moment(cur), 'd'),
    false,
  )
}

// 確認是否為補班日
export function isForceBusinessDay(date: moment.Moment) {
  return forceBusinessDayList.reduce(
    (pre, cur) => pre || date.isSame(moment(cur), 'd'),
    false,
  )
}

// 計算下個工作日
export function addWeekDaysWithHoliday(date: moment.Moment, amount: number) {
  let nextDate = date

  while (amount > 0) {
    nextDate = moment(nextDate).add(1, 'days')
    if (
      // 不是假日
      !isHoliday(nextDate)
      // 是平日或補班日
      && (isWeekDay(nextDate) || isForceBusinessDay(nextDate))
    )
      amount = amount - 1
  }

  // 回傳下個工作日
  return nextDate
}

// 計算兩個日期間有幾個國定假日
export function countHolidays(startDate, endDate) {
  let count = 0
  const list = Array.from(
    moment.range(moment(startDate), moment(endDate)).by('days'),
  )

  for (const date of list) {
    if (isHoliday(date))
      count = count + 1
  }

  return count
}

// 計算兩個日期間有幾個補班日
export function countForceBusinessDay(startDate, endDate) {
  let count = 0
  const list = Array.from(
    moment.range(moment(startDate), moment(endDate)).by('days'),
  )

  for (const date of list) {
    if (isForceBusinessDay(date))
      count = count + 1
  }

  return count
}

// 計算兩個日期間有幾個放假日
export function countHolidayAndWeekendDay(startDate, endDate) {
  let count = 0
  const list = Array.from(
    moment.range(moment(startDate), moment(endDate)).by('days'),
  )

  for (const date of list) {
    if (isHoliday(date) || (!isForceBusinessDay(date) && isWeekendDay(date)))
      count = count + 1
  }

  return count
}
